import React from "react"
import { Image } from "../VindiciaComponents"

const IntroductionText = ({ data }) => {
  const section = data.primary
  return (
    <section className="uk-section">
      <div className="uk-container two-column-with-image">
        <div
          className={`uk-child-width-1-2@m uk-flex ${
            section.image_position === "left" ? "uk-flex-row-reverse" : ""
          }`}
          data-uk-grid
        >
          <div className="uk-padding">
            <h2 className="uk-margin-small-bottom">{section.introduction_headline}</h2>
            <div
              className="uk-margin-remove-vertical"
              dangerouslySetInnerHTML={{ __html: section.introduction_copy.html }}
            ></div>
            <a
              href={section.introduction_cta_url.url}
              target={section.introduction_cta_url.target}
              className="uk-button uk-button-large uk-button-primary uk-border-pill uk-light"
            >
              {section.introduction_cta_text}
            </a>
          </div>
          <div className="uk-position-relative uk-visible@m uk-center">
            <Image
              className="uk-align-center"
              placeholder={section.introduction_image.fixed.src}
              src={section.introduction_image.fixed.src}
              srcSet={section.introduction_image.fixed.srcSet}
              width={section.introduction_image.fixed.width}
              height={section.introduction_image.fixed.height}
              alt="Introduction"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default IntroductionText
