import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Hero from "../components/Home/Hero"
import Introduction from "../components/Home/Introduction"
import Solutions from "../components/Home/Solutions"
import Benefits from "../components/Home/Benefits"
import Testimonials from "../components/Home/Testimonials"
import Clients from "../components/Home/Clients"
import SEO from "../components/seo"
import { withPreview } from "gatsby-source-prismic"

const IndexPage = ({ data }) => {
  const content = data.prismicHomePage.data
  return (
    <Layout colorTheme={content.header_color_theme}>
      <SEO
        title={content.seo_title}
        description={content.seo_description}
        keywords={content.seo_keywords}
      />
      <Hero
        data={content}
        colorTheme={content.header_color_theme}
        topBgMobilePosition={content.top_bg_mobile_position}
      />
      <Introduction data={content} />
      <Solutions data={content} />
      <Benefits data={content} />
      <Testimonials data={content} />
      <Clients data={content} />
    </Layout>
  )
}

export default withPreview(IndexPage)

export const homePageQuery = graphql`
  {
    prismicHomePage {
      data {
        color_theme
        seo_title
        seo_keywords
        seo_description
        hero_title
        flipping_words
        hero_subtitle
        hero_button_label
        hero_button_label_2
        hero_button_link
        hero_button_link_2
        hero_block_position
        hero_text_position
        hero_background_type
        hero_video_url {
          target
          url
        }
        header_color_theme
        top_bg_mobile_position
        hero_banner_image {
          fluid(maxWidth: 1400) {
            ...GatsbyPrismicImageFluid_noBase64
          }
          fixed(width: 600, imgixParams: { q: 20, blur: 80 }) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        hero_image {
          alt
          fixed(width: 1400) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        hero_video {
          url
        }
        product_disable
        product_section_title {
          html
        }
        product_section_background_image {
          fluid(maxWidth: 1400, maxHeight: 555) {
            ...GatsbyPrismicImageFluid_noBase64
          }
        }
        product_card {
          headline
          copy {
            html
          }

          product_link
        }
        benefits_title
        benefits_copy {
          html
        }
        benefits_card {
          image {
            fixed(width: 300) {
              ...GatsbyPrismicImageFixed_noBase64
            }
            alt
          }
          headline
          copy {
            html
          }
        }
        testimonial_section_theme
        section_background_color
        testimonial_quote {
          copy
          source
          logo {
            fixed(width: 150) {
              ...GatsbyPrismicImageFixed_noBase64
            }
            alt
          }
          link {
            target
            url
          }
          link_title
        }
        client_title {
          html
        }
        clients {
          logo {
            fixed(width: 130) {
              ...GatsbyPrismicImageFixed_noBase64
            }
            alt
          }
        }
        body {
          ... on PrismicHomePageBodyIntroductionText {
            id
            slice_type
            primary {
              image_position
              introduction_headline
              introduction_copy {
                html
              }
              introduction_cta_text
              introduction_cta_url {
                target
                url
              }
              introduction_image {
                alt
                fixed(height: 400) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
            }
          }
          ... on PrismicHomePageBody2Column {
            primary {
              section_id
              section_name
              section_theme
              section_background_color
              copy {
                html
              }
              image {
                fixed(width: 500) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
              cta_url {
                target
                url
              }
              cta_text
            }
            slice_label
            slice_type
          }
          ... on PrismicHomePageBody2ColumnVideo {
            id
            slice_label
            slice_type
            primary {
              copy {
                html
              }
              cta_url {
                target
                url
              }
              cta_text
              section_id
              section_name
              section_theme
              section_background_color
              vimeo_id
            }
          }
          ... on PrismicHomePageBodyFullWidthBanner {
            primary {
              section_id
              section_name
              copy {
                html
              }
              background_image {
                fluid(maxWidth: 1400) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
              cta_url {
                url
                target
              }
              cta_text
              theme_color_section
            }
            slice_type
          }
          ... on PrismicHomePageBodyFullWidthBannerWithVideoOverlay {
            primary {
              section_id
              section_name
              copy {
                html
              }
              background_image {
                fluid(maxWidth: 1400) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
            slice_type
          }
          ... on PrismicHomePageBody2ColumnWithAccordion {
            primary {
              section_id
              section_name
              copy {
                html
              }
              image {
                fixed(width: 500) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
            }
            slice_label
            slice_type
            items {
              accordion_element_body {
                html
              }
              accordion_element_title
            }
          }
          ... on PrismicHomePageBody3ColumnWithIcon {
            primary {
              section_id
              section_name
            }
            items {
              icon_image {
                fixed(height: 50) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
              short_copy {
                html
              }
              cta_link_url {
                target
                url
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicHomePageBody1ColumnContainerSmall {
            primary {
              section_id
              section_name
              copy {
                html
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicHomePageBody3ColumnGrid {
            primary {
              section_id
              section_name
              section_title {
                text
              }
            }
            items {
              sub_mgmt_items {
                uid
                document {
                  ... on PrismicSubscriptionManagement {
                    data {
                      short_copy {
                        html
                      }
                      short_title
                      icon_image {
                        fixed(height: 60) {
                          ...GatsbyPrismicImageFixed_noBase64
                        }
                      }
                      url_prefix
                      second_url_prefix
                    }
                  }
                }
              }
            }
            slice_type
            slice_label
          }
          ... on PrismicHomePageBody3ColumnCards {
            primary {
              section_id
              section_name
              section_title_centered {
                text
              }
              background_color
              card_background_color
            }
            items {
              card_body_content {
                html
              }
              card_header_content {
                html
              }
              card_header_image {
                fixed(width: 200) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
            }
            slice_type
          }
          ... on PrismicHomePageBodyScrollerWithBg {
            id
            slice_type
            primary {
              section_name
              section_id
              background_image {
                alt
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
            items {
              slide_title
              slide_copy {
                html
              }
              slide_image_desktop {
                alt
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
              slide_image_mobile {
                alt
                fixed(width: 500) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
            }
          }
          ... on PrismicHomePageBodyCtaBlock {
            primary {
              section_id
              section_theme
              title {
                html
              }
              description {
                html
              }
              cta_button_link {
                target
                url
              }
              cta_button_text
              section_name
              section_background
              section_vimeo_id
              section_vimeo_height
              section_image {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
            slice_type
          }
          ... on PrismicHomePageBodyNumberFact {
            primary {
              section_id
              section_name
              section_title {
                html
              }
              section_description {
                html
              }
            }
            slice_type
            items {
              number_field
              prefix_text {
                html
                raw
              }
              postfix_text {
                html
                raw
              }
              description_text {
                html
              }
            }
          }
          ... on PrismicHomePageBodySingleCounter {
            primary {
              section_id
              section_name
              section_heading {
                html
              }
              section_description {
                html
              }
              button_link {
                target
                url
              }
              button_label
              number_field
              prefix_text {
                html
                raw
              }
              postfix_text {
                html
                raw
              }
              description_text {
                html
              }
              numbers_color
            }
            slice_type
          }
          ... on PrismicHomePageBodyRelatedMaterialComponent {
            primary {
              section_id
              section_name
              section_title {
                html
              }
            }
            items {
              item_icon {
                alt
                fluid(maxWidth: 300) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
                url
              }
              item_doc_title {
                html
              }
              link_item {
                url
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicHomePageBodyPromotionComponent {
            primary {
              section_id
              section_name
              section_promo_title
              section_description {
                html
              }
            }
            slice_type
            items {
              item_title
              item_description {
                html
              }
              button_link {
                target
                url
              }
              button_label
              item_image {
                alt
                fluid(maxWidth: 760) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
          }
          ... on PrismicHomePageBodyImageGallery {
            primary {
              section_id
              section_name
              section_description {
                html
              }
            }
            slice_type
            items {
              item_description {
                html
              }
              item_image {
                alt
                fluid(maxWidth: 600) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
          }
          ... on PrismicHomePageBodyTestimonials {
            primary {
              section_id
              section_name
              testimonials_image {
                fluid(maxWidth: 1400) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
            slice_label
            slice_type
            items {
              case_study
              copy
              source
              logo {
                fixed(width: 200) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
            }
          }
          ... on PrismicHomePageBody2ColumnVideo {
            id
            slice_label
            slice_type
            primary {
              copy {
                html
              }
              section_id
              section_name
              vimeo_id
            }
          }
          ... on PrismicHomePageBodyHoverBackgroundZoomBlock {
            slice_type
            items {
              heading {
                html
              }
              description {
                html
              }
              button_link {
                target
                url
              }
              button_link_text
              background {
                alt
                fluid(maxWidth: 760) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
              overlay_color
            }
          }
          ... on PrismicHomePageBodyLogoCarousel {
            primary {
              section_id
              section_name
              title {
                html
              }
              description {
                html
              }
              button_text
              button_url {
                target
                url
              }
            }
            slice_type
            items {
              logo {
                fixed(width: 150) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
            }
          }
          ... on PrismicHomePageBody4ColumnIcon {
            slice_type
            primary {
              section_theme
              section_background_color
              section_heading {
                html
              }
              section_description {
                html
              }
            }
            items {
              title
              description {
                html
              }
              icon {
                alt
                fluid(maxWidth: 50) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
          }
          ... on PrismicHomePageBody3ColumnIconCards {
            slice_type
            primary {
              section_theme
              section_background_color
              cards_background_color
              section_heading {
                html
              }
              section_description {
                html
              }
            }
            items {
              title
              description {
                html
              }
              icon {
                alt
                fluid(maxWidth: 50) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
              link_url {
                target
                url
              }
              link_title
            }
          }
          ... on PrismicHomePageBody1ColumnRepeatable {
            slice_label
            slice_type
            primary {
              section_id
              section_theme
              section_background_color
              section_title {
                html
              }
              section_description {
                html
              }
            }
            items {
              copy_text {
                html
              }
              author {
                html
              }
              job_title {
                html
              }
              cta_text
              cta_url {
                url
                target
              }
            }
          }
        }
      }
    }
  }
`
