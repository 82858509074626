import React from "react"
import { Image } from "../VindiciaComponents"

const Clients = ({ data }) => {
  return (
    <section className="homepage-clients uk-section uk-section-muted uk-section-small">
      <div className="uk-container uk-container-expand">
        {data.client_title && (
          <div
            className="uk-text-center default-theme"
            dangerouslySetInnerHTML={{ __html: data.client_title.html }}
          />
        )}
        <div
          className="uk-slider-container"
          data-uk-slider="autoplay: true; sets: false; autoplay-interval: 2000; clsActivated: uk-transition-active"
        >
          <div
            className="uk-slider-items uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-5@m"
            data-uk-grid
          >
            {data.clients.map((image, i) => (
              <div key={`client-${i}`} className="uk-text-center">
                <Image
                  src={image.logo.fixed.src}
                  srcSet={image.logo.fixed.srcSet}
                  width={image.logo.fixed.width}
                  height={image.logo.fixed.height}
                  className="client-logo"
                  alt={image.logo.alt}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Clients
