import React from "react"
//import { linkResolver } from 'gatsby-source-prismic'
import { Image } from "../VindiciaComponents"

const Benefits = ({ data }) => {
  return (
    data.benefits_title && (
      <section className="homepage-benefits uk-section uk-section-default uk-section-small">
        <div className="uk-container uk-container-expand">
          <div className="uk-grid-margin" data-uk-grid>
            <div className="uk-width-1-4@m">
              <h2>{data.benefits_title}</h2>
              <div dangerouslySetInnerHTML={{ __html: data.benefits_copy.html }} />
            </div>
            <div
              className="uk-width-expand uk-padding uk-position-relative uk-visible-toggle uk-align-right uk-slider"
              data-uk-slider="autoplay: true"
            >
              <div
                className="uk-slider-items uk-grid uk-child-width-1-3@s"
                data-uk-grid
                data-uk-height-match="target: > div > .uk-card > .uk-card-body"
              >
                {data.benefits_card.map((card, i) => (
                  <div key={`card-${i}`}>
                    <div key={`card-${i}`} className="uk-card uk-card-default uk-card-small">
                      <div className="uk-card-media-top">
                        <Image
                          src={card.image.fixed.src}
                          srcSet={card.image.fixed.srcSet}
                          alt={card.headline}
                          style={{
                            width: "100%",
                          }}
                        />
                      </div>
                      <div className="uk-card-body">
                        <h4 className="uk-margin-small-bottom uk-margin-remove-top uk-margin-remove-adjacent">
                          {card.headline}
                        </h4>
                        <div dangerouslySetInnerHTML={{ __html: card.copy.html }} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="uk-position-bottom-center uk-position-small uk-padding-top">
                <ul className="uk-slider-nav uk-dotnav"></ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  )
}

export default Benefits
