import React from "react"
import { Image } from "../VindiciaComponents"

const Testimonials = ({ data }) => {
  const theme = data.testimonial_section_theme || "uk-light"

  return (
    <section
      className={`uk-cover-container homepage-testimonials ${theme}`}
      style={{ backgroundColor: data.section_background_color || "#fff" }}
    >
      <div className="veil">
        <div className="uk-container uk-flex uk-flex-center">
          <div className="slider-wrapper uk-width-3-5@m uk-margin-medium-top uk-margin-medium-bottom">
            <div
              className="uk-slider uk-slider-container"
              data-uk-slider="autoplay: true; easing: cubic-bezier(.16,.75,.47,1)"
            >
              <ul className="uk-slider-items">
                {data.testimonial_quote.map((quote, i) => (
                  <li key={`quote-${i}`} className="uk-width-1-1">
                    <div className="quote-wrapper">
                      <p className="quote">{quote.copy}</p>
                      <p className="source uk-margin-remove-bottom">{quote.source}</p>
                      <Image
                        className="logo"
                        src={quote.logo.fixed.src}
                        srcSet={quote.logo.fixed.srcSet}
                        alt={quote.logo.alt}
                      />
                      <p>
                        <a href={quote.link.url} target={quote.link.target}>
                          {quote.link_title}
                          <span uk-icon="arrow-right"></span>
                        </a>
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
              <ul
                className="uk-slider-nav uk-dotnav uk-flex-right"
                style={{ marginRight: "30px", marginBottom: "30px" }}
              >
                <li></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Testimonials
