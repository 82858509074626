import React from "react"
import { Background } from "../VindiciaComponents"

const Solutions = ({ data }) => {
  if (!data.product_disable) {
  return (<section
      style={{
        top: "-50px",
      }}
      className="homepage-products uk-section uk-position-relative uk-padding-remove-bottom"
    >
      <Background
        src={data.product_section_background_image.fluid.src}
        className="background-image"
        style={{
          backgroundSize: "cover",
        }}
      >
        {/*
            <div className="uk-container uk-text-center">
                <h2 className="uk-text-center">Meet our Solutions and Plans.</h2>
            </div>
            */}
        <div className="uk-container uk-padding">
          <div
            className="uk-child-width-1-3@m"
            data-uk-grid
            data-uk-height-match="target: > div > .uk-card > .uk-card-body"
          >
            {data.product_card.map((card, i) => (
              <div key={`card-${i}`}>
                <div
                  className={
                    "uk-card uk-card-default uk-card-small uk-padding uk-light homepage-products-card homepage-products-card-" +
                    (i + 1)
                  }
                >
                  <div className="uk-card-body uk-padding-remove-horizontal">
                    <h3 className="uk-margin-small-bottom uk-margin-remove-top uk-margin-remove-adjacent">
                      {card.headline}
                    </h3>
                    <div dangerouslySetInnerHTML={{ __html: card.copy.html }} />
                  </div>
                  <div className="uk-card-footer uk-padding-remove">
                    <a href={card.product_link}>
                      Learn more <span uk-icon="arrow-right"></span>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Background>
    </section>
  )}

  return '';
}

export default Solutions
