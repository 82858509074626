import React from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

const CountValueComponent = ({ number, color }) => {
  return (
    <CountUp end={number} decimals={number % 1 === 0 ? 0 : 1}>
      {({ countUpRef, start }) => (
        <VisibilitySensor onChange={start} delayedCall>
          <div className="counter" ref={countUpRef} style={{color}} />
        </VisibilitySensor>
      )}
    </CountUp>
  )
}

const SingleCounter = ({ data }) => {
  const { primary: section } = data;

  return (
    <section id={section.section_id} className="uk-section uk-container uk-text-center">
      <div
        className="uk-container uk-container-small uk-text-center"
        dangerouslySetInnerHTML={{ __html: section.section_heading.html }}
      />
      <div
        className="uk-container uk-container-small uk-margin-bottom"
        dangerouslySetInnerHTML={{ __html: section.section_description.html }}
      />
      <div className="uk-text-center uk-flex uk-flex-center uk-flex-middle uk-margin-small-bottom">
        <div className="data-item">
          <div className="prefix-text" dangerouslySetInnerHTML={{ __html: section.prefix_text.html }} />
          <CountValueComponent number={section.number_field} color={section.numbers_color} />
          <div
            className="postfix-text"
            dangerouslySetInnerHTML={{ __html: section.postfix_text.html }}
          />
        </div>
      </div>
      <div
        className="data-description"
        dangerouslySetInnerHTML={{ __html: section.description_text.html }}
      />
      {section.button_link && !!section.button_link.url && (
        <a
          className="uk-button uk-button-primary uk-border-pill"
          href={section.button_link.url}
          target={section.button_link.target}
        >
          {section.button_label}
        </a>
      )}
    </section>
  )
};

export default SingleCounter
