import React, { useEffect, useRef } from "react"
import TextLoop from "react-text-loop"
// import ReactPlayer from "react-player"
import { Background, Image, VideoBackground } from "../VindiciaComponents"

const Hero = ({ data, colorTheme = "light", topBgMobilePosition = false }) => {
  const textPositionClass =
    data.hero_text_position === "right"
      ? "uk-text-right"
      : data.hero_text_position === "center"
      ? "uk-text-center"
      : "uk-text-left"

  const blockPositionClass =
    data.hero_block_position === "right"
      ? "uk-flex-right"
      : data.hero_block_position === "center"
      ? "uk-flex-center"
      : "uk-flex-left"

  const theme = colorTheme === "light" ? "uk-dark" : "uk-light"
  const customBgPosition = topBgMobilePosition ? "custom-bg-position" : ""

  const textShadow =
    colorTheme === "light"
      ? {}
      : {
          textShadow:
            "rgba(255, 255, 255, 1) 6px 0px 7px, rgba(255, 255, 255, 1) 0px 0px 10px, rgba(255, 255, 255, 1) 0px 0px 7px",
        }

  const ref = useRef(null)

  const separatedTitle = data.hero_title.split("-flip-")

  const flippingWords = data.flipping_words.split(",")

  useEffect(() => {
    const isBookDemoBtn = ref.current?.href?.includes("/#book-a-demo")

    if (isBookDemoBtn) {
      setTimeout(() => {
        window.loadDriftWidgetOnScroll()
      }, 100)
    }
  }, [])

  return (
    <header className={`homepage-hero-custom-styles uk-position-relative ${theme}`}>
      <div className={theme}>
        <Background
          src={data.hero_banner_image?.fluid ? data.hero_banner_image.fluid.src : null}
          srcSet={data.hero_banner_image?.fluid ? data.hero_banner_image.fluid.srcSet : null}
          placeholder={data.hero_banner_image?.fixed ? data.hero_banner_image.fixed.src : null}
          className={`background-image ${customBgPosition}`}
        >
          {data.hero_video_url?.url && <VideoBackground url={data.hero_video_url.url} />}
          <div
            className={`hero-copy uk-flex ${blockPositionClass} uk-container uk-position-relative uk-margin-large-top uk-flex-between uk-flex-middle uk-flex-column uk-flex-row@m`}
          >
            <div
              className={`uk-width-2-5@s ${textPositionClass}`}
              data-uk-scrollspy="cls: uk-animation-slide-right-medium; target: > *; delay: 150"
            >
              <h1 style={textShadow}>
                {separatedTitle[0]}
                {flippingWords.length && (
                  <p className="uk-margin-remove">
                    <TextLoop interval={3000} mask={true}>
                      {flippingWords.map((text, i) => (
                        <span key={i} style={{ color: "#4d13f2" }}>
                          {text}
                        </span>
                      ))}
                    </TextLoop>
                  </p>
                )}
                {separatedTitle[1]}
              </h1>
              <h4 className="uk-margin-remove-top">{data.hero_subtitle}</h4>
              <a
                href={data.hero_button_link}
                className="uk-button uk-button-large uk-button-primary uk-border-pill uk-margin-right uk-margin-small-bottom"
                ref={ref}
              >
                {data.hero_button_label}
              </a>
              {data.hero_button_label_2 && (
                <a
                  href={data.hero_button_link_2}
                  className="uk-button uk-button-large uk-button-primary uk-border-pill uk-margin-small-bottom"
                  ref={ref}
                >
                  {data.hero_button_label_2}
                </a>
              )}
            </div>
            {data.hero_image !== null && !data.hero_video?.url && (
              <div className="uk-width-1-2@s uk-flex-first uk-margin-bottom uk-flex-last@m">
                <Image
                  src={data.hero_image.fixed.src}
                  srcSet={data.hero_image.fixed.srcSet}
                  alt={data.hero_image.alt}
                  width={data.hero_image.fixed.width}
                  height={data.hero_image.fixed.height}
                  style={{ objectFit: "contain" }}
                />
              </div>
            )}
            {data.hero_video?.url && (
              <div className="uk-width-1-2@s uk-flex-first uk-margin-bottom uk-flex-last@m">
                <div className="uk-width-1-1" style={{ height: "360px" }}>
                  <iframe
                    // src="https://player.vimeo.com/video/252443587?autoplay=1&muted=1&dnt=1&controls=1"
                    src={`${data.hero_video?.url}?autoplay=1&muted=1&dnt=1&controls=1`}
                    className="optanon-category-C0004 uk-width-1-1 uk-height-1-1"
                  ></iframe>
                </div>
              </div>
            )}
            {/*{data.hero_video?.url && (*/}
            {/*  <div className="uk-width-1-2@s uk-flex-first uk-margin-bottom uk-flex-last@m">*/}
            {/*    <ReactPlayer*/}
            {/*      // ref={playerRef}*/}
            {/*      // url={data.hero_video.url}*/}
            {/*      url="https://vimeo.com/252443587"*/}
            {/*      // playing*/}
            {/*      // playsinline*/}
            {/*      // muted={true}*/}
            {/*      loop*/}
            {/*      controls*/}
            {/*      width="100%"*/}
            {/*      config={{*/}
            {/*        vimeo: {*/}
            {/*          playerOptions: {*/}
            {/*            url: "https://vimeo.com/252443587",*/}
            {/*            dnt: true,*/}
            {/*            muted: true,*/}
            {/*            playsinline: true,*/}
            {/*            autoplay: true,*/}
            {/*          },*/}
            {/*        },*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*)}*/}
          </div>
        </Background>
      </div>
    </header>
  )
}

export default Hero
