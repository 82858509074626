import React from "react"
import IntroductionText from "../Content/IntroductionText"
import OneColumn from "../Content/OneColumn"
import TwoColumnWithImage from "../Content/TwoColumnWithImage"
import TwoColumnWithVideo from "../Content/TwoColumnWithVideo"
import TwoColumnWithAccordion from "../Content/TwoColumnWithAccordion"
import FullWidthBanner from "../Content/FullWidthBanner"
import FullWidthBannerWithVideoOverlay from "../Content/FullWidthBannerWithVideoOverlay"
import ThreeColumnWithIcon from "../Content/ThreeColumnWithIcon"
import ThreeColumnGrid from "../Content/ThreeColumnGrid"
import SliderWithBackground from "../Content/SliderWithBackground"
import ThreeColumnCards from "../Content/ThreeColumnCards"
import { Image } from "../VindiciaComponents"
import CTABlock from "../Content/CTABlock"
import NumberFact from "../Content/NumberFact"
import RelatedMaterial from "../Content/RelatedMaterial"
import PromotionalComponent from "../Content/PromotionalComponent"
import ImageGallery from "../Content/ImageGallery"
import Testimonials from "../Product/Testimonials"
import Video from "../Content/Video"
import HoverBackgroundZoom from "../Content/HoverBackgroundZoom"
import SingleCounter from "../Content/SingleCounter"
import LogoCarousel from "../Content/LogoCarousel"
import FourColumnWithIcon from "../Content/FourColumnWithIcon"
import ThreeColumnIconCard from "../Content/ThreeColumnIconCard"
import OneColumnScroller from "../Content/OneColumnScroller"

const Introduction = ({ data }) => {
  const contentType = (section, i) => {
    let template
    const type = section.slice_type

    switch (type) {
      case "introduction_text":
        template = <IntroductionText data={section} key={i} />
        break
      case "1-column_container-small":
        template = <OneColumn key={`section-${section.primary.section_id}`} data={section} />
        break
      case "2-column":
        template = (
          <TwoColumnWithImage key={`section-${section.primary.section_id}`} data={section} />
        )
        break
      case "2-column-video":
        template = (
          <TwoColumnWithVideo key={`section-${section.primary.section_id}`} data={section} />
        )
        break
      case "2-column_with_accordion":
        template = (
          <TwoColumnWithAccordion key={`section-${section.primary.section_id}`} data={section} />
        )
        break
      case "full_width_banner":
        template = <FullWidthBanner key={`section-${section.primary.section_id}`} data={section} />
        break
      case "full_width_banner_with_video_overlay":
        template = (
          <FullWidthBannerWithVideoOverlay
            key={`section-${section.primary.section_id}`}
            data={section}
          />
        )
        break
      case "3-column_with_icon":
        template = (
          <ThreeColumnWithIcon key={`section-${section.primary.section_id}`} data={section} />
        )
        break
      case "3-column_grid":
        template = <ThreeColumnGrid key={`section-${section.primary.section_id}`} data={section} />
        break
      case "scroller_with_bg":
        template = (
          <SliderWithBackground key={`section-${section.primary.section_id}`} data={section} />
        )
        break
      case "3-column-cards":
        template = (
          <ThreeColumnCards key={`section-${section.primary.section_id}`} data={section}>
            {section.items.map((item, i) => {
              const styles = {
                boxShadow: "none",
                borderRadius: "10px",
                backgroundColor: section.primary.card_background_color || "white",
              }

              return (
                <li key={`card-${i}`}>
                  <div
                    className="uk-card uk-card-default uk-card-small"
                    style={{ ...styles }}
                    data-uk-card
                  >
                    <div className="uk-card-media-top uk-text-center">
                      {item.card_header_image.fixed && (
                        <Image
                          src={item.card_header_image.fixed.src}
                          srcSet={item.card_header_image.fixed.srcSet}
                          alt="Vindicia"
                        />
                      )}
                    </div>
                    <div className="uk-card-body uk-text-center">
                      <div dangerouslySetInnerHTML={{ __html: item.card_header_content.html }} />
                      <div dangerouslySetInnerHTML={{ __html: item.card_body_content.html }} />
                    </div>
                  </div>
                </li>
              )
            })}
          </ThreeColumnCards>
        )
        break
      case "cta_block":
        template = <CTABlock key={`section-${section.primary.section_id}`} data={section} />
        break
      case "number_fact":
        template = <NumberFact key={`section-${section.primary.section_id}`} data={section} />
        break
      case "related_material_component":
        template = <RelatedMaterial key={`section-${section.primary.section_id}`} data={section} />
        break
      case "promotion_component":
        template = (
          <PromotionalComponent key={`section-${section.primary.section_id}`} data={section} />
        )
        break
      case "image_gallery":
        template = <ImageGallery key={`section-${section.primary.section_id}`} data={section} />
        break
      case "testimonials":
        template = (
          <Testimonials
            key={`section-${section.primary.section_id}`}
            data={{
              testimonials_image: section.primary.testimonials_image,
              testimonial_quote: section.items,
            }}
          />
        )
        break
      case "video_component":
        template = <Video key={`section-${section.primary.section_id}`} data={section} />
        break
      case "single_counter":
        template = <SingleCounter key={`section-${section.primary.section_id}`} data={section} />
        break
      case "hover_background_zoom_block":
        template = (
          <HoverBackgroundZoom key={`section-${section.primary.section_id}`} data={section} />
        )
        break
      case "logo_carousel":
        template = <LogoCarousel key={`section-${section.primary.section_id}`} data={section} />
        break
      case "4-column-icon":
        template = (
          <FourColumnWithIcon key={`section-${section.primary.section_id}`} data={section} />
        )
        break
      case "3-column-icon-cards":
        template = (
          <ThreeColumnIconCard key={`section-${section.primary.section_id}`} data={section} />
        )
        break
      case "1-column-repeatable":
        template = (
          <OneColumnScroller key={`section-${section.primary.section_id}`} data={section} />
        )
        break

      default:
        break
    }

    return template
  }

  return <div>{data.body.map((section, i) => contentType(section, i))}</div>
}

export default Introduction
